//Images
import img_1 from '../assets/images/img_1.jpeg';
import img_2 from '../assets/images/img_2.jpeg';
import img_3 from '../assets/images/img_3.jpeg';
import img_4 from '../assets/images/img_4.jpeg';
import img_5 from '../assets/images/img_5.jpeg';
import img_6 from '../assets/images/img_6.jpeg';
import cat_1 from '../assets/images/cat_1.jpeg';
import cat_2 from '../assets/images/cat_2.jpeg';
import cat_3 from '../assets/images/cat_3.jpeg';
//News
import news1 from '../assets/images/news-1.png';
import news2 from '../assets/images/news-2.jpg';
import news3 from '../assets/images/news-3.jpg';
import news4 from '../assets/images/news-4.png';
import news5 from '../assets/images/news-5.jpg';
import pdf1 from '../assets/anti-caduta.pdf';
import pdf2 from '../assets/brevetto-salvita.pdf';
//Prove
import prova1 from '../assets/images/prova-int-1.jpeg';
import prova2 from '../assets/images/prova-int-2.jpeg';
import prova3 from '../assets/images/prova-int-3.jpeg';
import prova4 from '../assets/images/prova-int-4.jpeg';
import prova5 from '../assets/images/prova-int-5.jpeg';
import prova6 from '../assets/images/prova-int-6.jpeg';
//Normativa
import norm1 from '../assets/images/norm-1.jpeg';
import norm2 from '../assets/images/norm-2.jpeg';
import norm3 from '../assets/images/norm-3.png';
import norm4 from '../assets/images/norm-4.jpeg';
import norm5 from '../assets/images/norm-5.jpeg';
import norm6 from '../assets/images/norm-6.jpeg';
import norm7 from '../assets/images/norm-7.jpeg';
import norm8 from '../assets/images/norm-8.jpeg';
// Forum
import forum1 from '../assets/images/1.jpg';
import forum2 from '../assets/images/2.jpg';
import forum3 from '../assets/images/3.jpg';
import forum4 from '../assets/images/4.jpg';
import forum5 from '../assets/images/5.jpg';
import forum6 from '../assets/images/6.jpg';
import forum7 from '../assets/images/7.jpg';
import forum8 from '../assets/images/8.jpg';

export const nav = [
  { id: 0, route: 'Home', path: '/' },
  { id: 1, route: 'Incidenti', path: '/incidenti' },
  { id: 2, route: 'Prove Angelocks', path: '/prove-angelocks' },
  { id: 3, route: 'Cataloghi', path: '/cataloghi' },
  { id: 4, route: 'Quadro Normativo', path: '/quadro-normativo' },
  { id: 5, route: 'Partner', path: '/partner' },
  { id: 6, route: 'Angelocks Special', path: '/angelocks-special' },
  { id: 7, route: 'Chi Siamo', path: '/chi-siamo' },
  { id: 8, route: 'Contatti', path: '/contatti' },
];

export const images = [
  { id: 0, src: img_1, alt: 'img_1' },
  { id: 1, src: img_2, alt: 'img_2' },
  { id: 2, src: img_3, alt: 'img_3' },
  { id: 3, src: img_4, alt: 'img_4' },
  { id: 4, src: img_5, alt: 'img_5' },
  { id: 5, src: img_6, alt: 'img_6' },
];

export const catImages = [
  { id: 0, src: cat_1, alt: 'cat' },
  { id: 1, src: cat_2, alt: 'cat' },
  { id: 2, src: cat_3, alt: 'cat' },
];

export const newsHome = [
  {
    id: 5,
    img: null,
    video: 'https://vimeo.com/387911977',
    desc: 'DALLA CONOSCENZA PIÙ VALORE',
    link:
      'https://media.ispfv.alkemyplay.it/docs/1012/dalla_conoscenza_piu_valore.pdf',
  },
  {
    id: 0,
    img: news1,
    video: null,
    desc: 'DALLA CONOSCENZA PIÙ VALORE',
    link:
      'https://media.ispfv.alkemyplay.it/docs/1012/dalla_conoscenza_piu_valore.pdf',
  },
  {
    id: 1,
    video: null,
    img: news2,
    desc: 'IL BREVETTO SALVAVITA',
    link: pdf2,
  },
  {
    id: 2,
    img: news4,
    desc: 'NASCE IL DISPOSITIVO ANTI-CADUTA',
    link: pdf1,
  },
  {
    id: 6,
    img: news3,
    desc: 'FORUM SICUREZZA SERRAMENTI DI BOLOGNA',
    link: '/forum-bologna',
  },
  {
    id: 4,
    img: news5,
    desc: 'APPLICABILE SU TUTTE LE FINESTRE E SCURETTI',
    link:
      'https://www.guidafinestra.it/accessori-per-infissi-niente-piu-cadute-di-ante-di-finestre/',
  },
];

export const proveAngelocks = [
  {
    id: 0,
    titolo: 'Prove Interne',
    desc:
      "La fase di sviluppo dell'accessorio ha comportato una verifica puntuale di tutte le parti costituenti Angelocks. Sono state effettuate più di 5000 prove interne.",
    img: [prova1, prova2, prova3, prova4, prova5, prova6],
    certificato: null,
  },
  {
    id: 1,
    titolo: 'Prove lab. certificato UNIUD',
    desc: null,
    certificato:
      'https://media.ispfv.alkemyplay.it/docs/1012/03.0_Prova_e_Relazione_UniUd_1_pagina.pdf',
  },
  {
    id: 2,
    titolo: 'Prove lab. certificato Malignani',
    desc: null,
    certificato:
      'https://media.ispfv.alkemyplay.it/docs/1012/03.1_Prova_Ist.Malignani_1_pagina.pdf',
  },
  {
    id: 3,
    titolo: 'Prove Istituto Giordano Internazionale',
    desc: null,
    certificato:
      'https://media.ispfv.alkemyplay.it/docs/1012/03.2_Prova_Ist.Giordano_352795.pdf',
  },
  {
    id: 4,
    titolo:
      'Prove trazione interna, rottura e controllo costante dei lotti produttivi',
    desc: 'Registrazione prove con sistemi computerizzati. ',
    certificato: null,
  },
];

export const incidenti = [
  {
    id: 0,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a02.jpeg?s=large',
    desc:
      'Alberi, cavi Enel e pali Telecom tranciati dal vento. A Campobasso torna la neve.',
    src:
      'https://www.primonumero.it/2019/03/non-solo-alberi-sulle-strade-cavi-enel-e-pali-telecom-tranciati-il-forte-vento-fa-danni/1530550316',
  },
  {
    id: 1,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a03.jpeg?s=large',
    desc:
      'Momenti di terrore in un liceo scientifico in provincia di Lecce. Una finestra è caduta sui banchi.',
    src:
      'http://www.ilgiornale.it/news/cronache/maglie-cade-finestra-scuola-feriti-tre-studenti-1612215.html',
  },
  {
    id: 2,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a04.jpeg?s=large',
    desc:
      'A Genzano è caduta una finestra nella palestra della scuola, sfiorati i ragazzi.',
    src:
      'https://www.ilmamilio.it/c/comuni/11093-genzano-cade-una-finestra-nella-palestra-della-scuola,-sfiorati-i-ragazzi.html',
  },
  {
    id: 3,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a05.jpeg?s=large',
    desc:
      "Finestra cade addosso a un bambino. E' successo alla scuola elementare Deletta di Erchie (BR).",
    src:
      'https://www.lagazzettadelmezzogiorno.it/news/brindisi/1084476/erchie-a-scuola-finestra-cade-addosso-a-un-bambino.html',
  },
  {
    id: 4,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a06.jpeg?s=large',
    desc:
      'Cadono le finestre della scuola media Da Vinci di Latina: due vetrate scorrevoli sono precipitate.',
    src:
      'https://www.latinaoggi.eu/news/cronaca/67088/cadono-le-finestre-della-scuola-media-da-vinci-di-latina',
  },
  {
    id: 5,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a64.jpeg?s=large',
    desc:
      'Cade finestra in aula, due ferite lievi. Al liceo artistico di Morbegno, "nessun problema strutturale".',
    src:
      'http://www.ansa.it/lombardia/notizie/2018/09/20/cade-finestra-in-aula-due-ferite-lievi_871d8a95-1177-4fc2-926e-bea9675d9a3e.html',
  },
  {
    id: 6,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a07.png?s=large',
    desc:
      'Bernareggio: crolla un vetro, scuola materna chiusa. Ferite due bidelle, controlli alla Rodari. ',
    src:
      'https://www.guidafinestra.it/incidente-scuola-cade-vetro-porta-di-emergenza/',
  },
  {
    id: 7,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a08.jpeg?s=large',
    desc:
      'Da Vinci, intervento flash della scuola: è tutto in regola. I tecnici hanno concluso gli interventi.',
    src:
      'https://www.latinaoggi.eu/news/cronaca/67113/finestre-cadute-alla-da-vinci-intervento-flash-della-scuola-a-tutto-in-regola',
  },
  {
    id: 8,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a09.jpeg?s=large',
    desc:
      'Maestra colpita in testa da una finestra. L’insegnante è finita in ospedale.',
    src:
      'https://torino.corriere.it/scuola/18_giugno_29/torino-maestra-colpita-testa-una-finestra-scuola-rayneri-d7e7bff6-7b79-11e8-ab49-1b15619f3f8e.shtml',
  },
  {
    id: 9,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a10.png?s=large',
    desc:
      "Maltempo a Napoli, morto un ragazzo schiacciato da un albero. Venti a 130 km all'ora.",
    src:
      'http://www.askanews.it/video/2018/10/29/maltempo-a-napoli-morto-un-ragazzo-schiacciato-da-un-albero-20181029_video_19304117/',
  },
  {
    id: 10,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a11.png?s=large',
    desc:
      'Finestre bloccate e aule come forni, la "prof" fa lezione nel corridoi. E\' successo a Rimini. ',
    src:
      'https://www.corriereromagna.it/news-rimini-26877-finestre-bloccate-aule-come-forni-prof-lezione-corridoio-html/',
  },
  {
    id: 11,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a12.jpeg?s=large',
    desc:
      "Si stacca un vetro e sfiora uno studente. E' successo all'istituto Keynes di Gazzada. ",
    src:
      'https://www.varesenews.it/2018/01/dalla-finestra-della-scuola-si-stacca-un-vetro-sfiora-uno-studente/685659/',
  },
  {
    id: 12,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a13.jpeg?s=large',
    desc:
      'Maglie, cade la finestra a scuola: feriti tre studenti. L’episodio nella succursale del liceo «Capece»',
    src:
      'https://www.lagazzettadelmezzogiorno.it/news/lecce/1088117/maglie-cade-la-finestra-a-scuola-feriti-tre-studenti.html',
  },
  {
    id: 13,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a16.png?s=large',
    desc:
      'Pordenone, finestrone si schianta sui banchi del liceo durante la ricreazione. Nessun ferito.',
    src:
      'https://messaggeroveneto.gelocal.it/pordenone/cronaca/2015/10/09/news/finestrone-si-schianta-sui-banchi-del-liceo-1.12233534',
  },
  {
    id: 14,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a17.png?s=large',
    desc:
      'Finestra va in mille pezzi, studenti miracolati. L’infisso di 70 chili si è schiantato su un banco.Alberi, cavi Enel e pali Telecom tranciati dal vento. A Campobasso torna la neve.',
    src:
      'https://messaggeroveneto.gelocal.it/udine/cronaca/2016/12/17/news/cade-la-finestra-studenti-miracolati-1.14577516',
  },
  {
    id: 15,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a18.png?s=large',
    desc:
      'Crolla finestra nella scuola. I soccorritori si sono recati sul posto e hanno valutato la donna ferita. ',
    src:
      'https://giornaledeinavigli.it/cronaca/crolla-finestra-scuola-locate-ferita-bidella/',
  },
  {
    id: 16,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a33.jpeg?s=large',
    desc:
      'Rieti, crolla finestra al Liceo Classico Varrone. Tragedia sfiorata, ragazzo ferito.',
    src:
      'https://www.ilmessaggero.it/rieti/rieti_finestra_classico_varrone-4237870.html',
  },
  {
    id: 17,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a36.png?s=large',
    desc:
      'Vetrata killer, nessun colpevole. Il giudice non ha ravvisato responsabilità.',
    src:
      'https://www.ilgazzettino.it/pay/padova_pay/albignasego_vetrata_killer_nessun_colpevole_il_giudice_laura_alcaro_non_ha-3823770.html',
  },
  {
    id: 18,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a34.jpeg?s=large',
    desc:
      'Crolla finestrone dell’ospedale addosso a un paziente, salvato da una volontaria che fa da scudo.',
    src: 'https://la-riviera.it/cronaca/crolla-finestrone-ospedale-s/',
  },
  {
    id: 19,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a38.jpeg?s=large',
    desc:
      'A causa del vento una lastra di vetro si è staccata ed è caduta in via del Mercato. ',
    src:
      'http://www.sienafree.it/provincia/142-siena/16940-siena-cade-lucernario-di-palazzo-pubblico-nessun-ferito-',
  },
  {
    id: 20,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a38.jpeg?s=large',
    desc:
      'Finestra caduta sui banchi di scuola, Di Baggio presenta interrogazione.',
    src:
      'https://quotidianomolise.com/finestra-caduta-sui-banchi-scuola-baggio-presenta-interrogazione/',
  },
  {
    id: 21,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a40.jpeg?s=large',
    desc:
      "Palestrina: Crolla un infisso alla scuola per l'infanzia Wojtyla. Ravvisati cedimenti strutturali. ",
    src: 'http://www.lanotizia2.it/palestrina-crolla-un-infisso-alla-gescal/',
  },
  {
    id: 22,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a49.jpeg?s=large',
    desc:
      "Cede finestra a scuola, feriti 2 alunni. E' successo nella Bergamasca, a causa del vento.",
    src:
      'http://www.ansa.it/sito/notizie/cronaca/2018/10/30/cede-finestra-a-scuola-feriti-2-alunni_fb9bb0f2-d064-46f2-8b22-f8931fa19859.html',
  },
  {
    id: 23,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a50.jpeg?s=large',
    desc:
      'Alberi spezzati, cartelli caduti, finestre rotte per le forti raffiche di vento.',
    src:
      'https://www.laguida.it/2019/03/11/alberi-spezzati-cartelli-caduti-finestre-rotte-per-le-forti-raffiche-di-vento/',
  },
  {
    id: 25,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a52.png?s=large',
    desc:
      'Finestra caduta, studenti in piazza. In sciopero 500 ragazzi del Malignani di Cervignano. ',
    src:
      'https://ilpiccolo.gelocal.it/trieste/cronaca/2016/12/20/news/finestra-caduta-studenti-in-piazza-1.14599621',
  },
  {
    id: 26,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a63.jpeg?s=large',
    desc:
      "Cade infisso in aula, alunni della scuola media miracolati. E' successo a Recale. ",
    src:
      'http://ftp.noicaserta.it/cade-infisso-in-aula-alunni-della-scuola-media-miracolati.html',
  },
];

export const normativa = [
  {
    id: 0,
    img: norm1,
    link:
      'http://www.lostrillonenews.it/2018/11/24/finestra-caduta-a-scuola-lamministrazione-non-si-strumentalizzino-i-piccoli-scolari/?fbclid=IwAR1dZgVTW19-WRP6pWPr7u4UlQSrFH51LYoDVAtlxrGFNC-d8lYQUnqWCmg',
    desc:
      'Finestra caduta a scuola, “Non si strumentalizzino i piccoli scolari”.',
  },
  {
    id: 1,
    img: norm2,
    link:
      'https://www.lavorincasa.it/caduta-in-condominio-e-risarcimento-danno/?fbclid=IwAR05TTpvTIz5q7DDRe9RMORDSl6kSqrZ5WwdPO-TaUdekFveJj0ZJIdKFu0/',
    desc: 'Caduta in condominio: quando chiedere il risarcimento del danno',
  },
  {
    id: 2,
    img: norm3,
    link:
      'http://www.dirittierisposte.it/Schede/Danno-alle-persone/Tipi-di-danno/danno_causato_dalle_cose_crolli_cadute_infiltrazioni_id1132756_art.aspx?fbclid=IwAR30CY3pYgBJ_4u1jsScpeClX2isGLn2Fk3WAb-ad2Eacbntg_vWg1LLElA',
    desc: 'Danno causato dalle cose (crolli, cadute, infiltrazioni).',
  },
  {
    id: 3,
    img: norm4,
    link:
      'https://www.avvocatirandogurrieri.it/leggi-e-diritto/infortuni-per-cadute-all-interno-del-condominio-e-sempre-responsabile-il-condominio-1?fbclid=IwAR0TbBrp0TpBGR1Fe3L013r65eJWLYES0sZrmt9v9cAncwX8OH2KocwvxaE',
    desc: 'Infortuni per cadute: è sempre responsabile il condominio? ',
  },
  {
    id: 4,
    img: norm5,
    link:
      'https://www.assicurazioni-alessandria.it/risarcimento-danni/lettera-risarcimento-condominio/?fbclid=IwAR2bDuLPaB85Y5ekF23uMwmyBVTGm2hiorHK__hnxoj3xX47xFXog7BaOfo',
    desc: 'Lettera di richiesta risarcimento danni al condominio: come fare?',
  },
  {
    id: 5,
    img: norm6,
    link:
      'http://www.ilcondominionuovo.it/novita/blog/le-cadute-in-condominio-la-responsabilita-del-condominio-ex-art-2051-c-c/?fbclid=IwAR0DtRW-lIFTiQvH_8cZHRN_Wv0yUe33-V4RV5rZWiJi-2Nv4SFYNWLDfR4',
    desc: "Cadute in condominio: di chi è la responsabilità dell'accaduto?",
  },
  {
    id: 6,
    img: norm7,
    link:
      'https://www.cutillogroup.com/2014/11/13/cumulo-polizze-assicurazione-risarcimento-danni/?fbclid=IwAR12aaC0kAmOZHlros1q8Y8cPxCAVZ4sb98Mx2dBUULXomxNHWX6HdwG6lo',
    desc: 'Cumulo tra polizze e risarcimento danni.',
  },
  {
    id: 7,
    img: norm8,
    link:
      'https://www.laleggepertutti.it/117995_il-risarcimento-dellalunno-che-si-fa-male-a-scuola?fbclid=IwAR2H309Ui4_mS6uByELOYgd-JwAzDj51k0P1R4dyjbkOZGo3UoGXdecn7KQ',
    desc: 'Il risarcimento dell’alunno che si fa male a scuola.',
  },
  {
    id: 8,
    img:
      'https://www.orizzontescuola.it/wp-content/uploads/2020/11/WhatsApp-Image-2020-11-19-at-14.01.26.jpeg',
    desc:
      'Cittadinanzattiva: nell’ultimo anno 50 episodi di crolli, distacco intonaco e cadute finestre nelle scuole',
    link:
      'https://www.orizzontescuola.it/cittadinanzattiva-nellultimo-anno-50-episodi-di-crolli-distacco-intonaco-e-cadute-finestre-nelle-scuole/',
  },
];

export const incidentiEstero = [
  {
    id: 0,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a20.jpeg?s=large',
    desc:
      'Boy killed after window falls on him from 20th floor in southern China.',
    src:
      'https://www.nzherald.co.nz/world/news/article.cfm?c_id=2&objectid=12241071',
  },
  {
    id: 1,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a21.jpeg?s=large',
    desc: 'Two hurt and woman arrested after window falls. ',
    src:
      'https://www.scmp.com/news/hong-kong/health-environment/article/3004010/two-hurt-and-woman-arrested-after-window-falls',
  },
  {
    id: 2,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a22.png?s=large',
    desc: 'Two workmen almost killed by a  window falling.',
    src:
      'https://www.thesun.co.uk/news/7410573/luxury-flat-window-falls-crushes-coach-driver-to-death/',
  },
  {
    id: 3,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a23.jpeg?s=large',
    desc: 'Man killed as window falls from luxury flat in London. ',
    src:
      'https://news.sky.com/story/man-killed-as-window-falls-from-luxury-flat-in-london-11515368',
  },
  {
    id: 4,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a24.jpeg?s=large',
    desc: 'Glass falls from condo building near Lake Shore and Bathurst.',
    src:
      'https://toronto.citynews.ca/2019/04/18/falling-glass-condo-lakeshore-bathurst/',
  },
  {
    id: 5,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a26.png?s=large',
    desc: '2 Employees Injured After Windows Fall From Exelon Building.',
    src:
      'https://baltimore.cbslocal.com/2018/10/10/windows-fall-from-exelon-building-employees-injured/',
  },
  {
    id: 6,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a27.jpeg?s=large',
    desc: 'Elderly man injured after glass panel falls from building.',
    src:
      'https://www.stuff.co.nz/national/111617697/glass-panel-falls-from-building-onto-willis-st-sidewalk-injuring-a-person',
  },
  {
    id: 7,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a28.jpeg?s=large',
    desc: 'Falling window at Toa Payoh HDB block narrowly misses a man.',
    src:
      'https://www.straitstimes.com/singapore/falling-window-at-toa-payoh-hdb-block-narrowly-misses-78-year-old-man',
  },
  {
    id: 8,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a29.jpeg?s=large',
    desc:
      "'Lucky nobody was killed': Window falls from top of 12-storey tower.",
    src:
      'https://www.canberratimes.com.au/story/5997057/lucky-nobody-was-killed-window-falls-from-top-of-12-storey-tower/',
  },
  {
    id: 9,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a31.jpeg?s=large',
    desc: 'Woman sent to hospital after being struck by falling window.',
    src:
      'https://coconuts.co/hongkong/news/woman-sent-to-hospital-after-being-struck-by-falling-window-in-tsuen-wan/',
  },
  {
    id: 10,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a35.png?s=large',
    desc: 'UPDATE | More windows fall out of Prichard Building in Huntington ',
    src:
      'https://www.wsaz.com/content/news/Wind-blowing-glass-out--506303681.html',
  },
  {
    id: 11,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a41.jpeg?s=large',
    desc: 'Las Vegas Hotel Window Falls 200 Feet to Street Below.',
    src:
      'https://www.theepochtimes.com/las-vegas-hotel-window-falls-200-feet-to-street-below_2667646.html',
  },
  {
    id: 12,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a42.jpeg?s=large',
    desc: 'Fifth floor window falls out of Connaught Road flats.',
    src:
      'https://www.theargus.co.uk/news/17625909.fifth-floor-window-falls-out-of-connaught-road-flats/',
  },
  {
    id: 13,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a44.png?s=large',
    desc:
      'Window falls on worker in Leawood construction accident, police say.',
    src:
      'https://www.kshb.com/news/local-news/window-unit-falls-on-worker-in-leawood-construction-accident-police-say',
  },
  {
    id: 14,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a45.jpeg?s=large',
    desc: 'No one hurt after glass falls from CRST building.',
    src:
      'https://cbs2iowa.com/news/local/no-one-hurt-after-glass-falls-from-crst-building',
  },
  {
    id: 15,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a46.jpeg?s=large',
    desc: 'Glass Pane Falls From Building In Midtown.',
    src: 'https://newyork.cbslocal.com/2018/09/13/glass-pane-falls-in-midtown/',
  },
  {
    id: 16,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a47.png?s=large',
    desc: 'Glass Panels Fall From Brigham and Women’s Hospital in Boston. ',
    src:
      'https://www.nbcboston.com/news/local/glass-panels-fall-at-brigham-and-womens-hospital/4653/',
  },
  {
    id: 17,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a53.jpeg?s=large',
    desc: 'Window falls out and shatters at Bradford M&S.',
    src:
      'https://www.thetelegraphandargus.co.uk/news/17267460.window-falls-out-and-shatters-at-bradford-ms/',
  },
  {
    id: 18,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a54.jpeg?s=large',
    desc: 'The Arch residents unhappy with response to window falls.',
    src:
      'https://www.scmp.com/news/hong-kong/article/1068175/larvotto-arch-residents-unhappy-response-window-falls',
  },
  {
    id: 19,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a56.png?s=large',
    desc:
      'Window falls on worker in Leawood construction accident, police say.',
    src:
      'https://www.cbc.ca/news/canada/british-columbia/work-stops-after-window-falls-from-vancouver-building-1.1295032',
  },
  {
    id: 20,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a57.png?s=large',
    desc: 'Man hurt by falling glass in the Loop.',
    src:
      'https://chicago.suntimes.com/2019/7/5/20683311/man-hurt-falling-glass-loop-michigan',
  },
  {
    id: 21,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a60.jpeg?s=large',
    desc: 'More action needed on falling windows.',
    src:
      'https://www.scmp.com/comment/insight-opinion/article/2188604/more-action-needed-falling-windows',
  },
  {
    id: 22,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a61.jpeg?s=large',
    desc: 'Arrested after window falls from building in Sham Shui Po market.',
    src:
      'https://coconuts.co/hongkong/news/2-elderly-women-hurt-1-arrested-after-window-falls-from-building-in-sham-shui-po-market/',
  },
  {
    id: 23,
    img:
      'http://site-007c08aa542e.aps.ispfv.alkemyplay.it/imgs/1012/a62.jpeg?s=large',
    desc: 'Window falls from HK hotel, killing tourist below ',
    src:
      'https://www.straitstimes.com/world/window-falls-from-hk-hotel-killing-tourist-below',
  },
];

export const clienti = [
  {
    field1: '2',
    cliente: 'Metra Spa',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via Stacca 1',
    cap: '25050',
    localita: 'Rodengo Saiano',
    provincia: 'BS',
    email: 'servizioclienti@metra.it',
    sito: 'http://www.metra.eu',
    tel: '030 68191',
  },
  {
    field1: '3',
    cliente: 'Vitale Giuseppe',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Strada Polesana per Badia 2/A',
    cap: '45026',
    localita: 'Lendinara',
    provincia: 'RO',
    email: 'info@vitalegiuseppe.it',
    sito: 'http://www.vitalegiuseppe.it/',
    tel: '0425 641712',
  },
  {
    field1: '4',
    cliente: 'Iasp di Giuseppin Giovanni Sas',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via Franca, 44',
    cap: '30026',
    localita: 'Summaga di Portogruaro',
    provincia: 'VE',
    email: 'info@iaspserramenti.it',
    sito: 'https://www.iasp.it/',
    tel: '0421 205200',
  },
  {
    field1: '5',
    cliente: 'Agb Alban Giacomo Spa',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via A. De Gasperi, 75',
    cap: '36060',
    localita: "Romano D'Ezzelino",
    provincia: 'VI',
    email: 'info@agb.it',
    sito: 'https://www.agb.it/it-it/home-it',
    tel: '0424 832832',
  },
  {
    field1: '6',
    cliente: 'Elledi Costruzioni di Lauriola Domenico',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Via Marsicana, 28',
    cap: '03039',
    localita: 'Sora',
    provincia: 'FR',
    email: 'info@elledicostruzioni.it',
    sito: 'https://www.elledicostruzioni.it/',
    tel: '',
  },
  {
    field1: '7',
    cliente: 'Lorenz di Alberto Mazzocato & C. Sas:',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via Spallanzani, 13',
    cap: '31044',
    localita: 'Montebelluna',
    provincia: 'TV',
    email: '',
    sito: 'http://www.imballaggilorenz.com/en/',
    tel: '432602658',
  },
  {
    field1: '8',
    cliente: 'Lico Santo Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Largo Alcide De Gasperi, 1',
    cap: '89900',
    localita: 'Vibo Valentia',
    provincia: 'VV',
    email: 'info@licosanto.it',
    sito: 'https://www.licosanto.it/',
    tel: '963255835',
  },
  {
    field1: '9',
    cliente: 'Z.M. di Zaccagnino e Celli Snc',
    'tipologia-cliente': 'Partner',
    indirizzo: 'via M. Curie, 15',
    cap: '50051',
    localita: 'Castelfiorentino',
    provincia: 'FI',
    email: 'info@zmserramenti.it',
    sito: 'https://www.zmserramenti.it/',
    tel: '0571 629609',
  },
  {
    field1: '10',
    cliente: 'SI-CA Serramenti Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via Roma 116',
    cap: '30030',
    localita: 'Pianiga',
    provincia: 'VE',
    email: '',
    sito: 'http://www.sicaserramenti.it/',
    tel: '415195277',
  },
  {
    field1: '11',
    cliente: 'Baglioni Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Strada Statale S.S. 321 Est,30',
    cap: '53040',
    localita: 'Cetona',
    provincia: 'SI',
    email: 'info@impresabaglioni.it',
    sito: 'https://www.baglionicostruzionirestauri.it/',
    tel: '0578 238668',
  },
  {
    field1: '12',
    cliente: 'Eurofinestra Sas',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Str. Provinciale 482,95/1',
    cap: '46037',
    localita: 'Governolo',
    provincia: 'MN',
    email: 'info@aurofinestra.it',
    sito: 'https://www.eurofinestra.it/',
    tel: '376668028',
  },
  {
    field1: '13',
    cliente: 'SO.ME.T Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via Aniello Palumbo, 81',
    cap: '80014',
    localita: 'Napoli',
    provincia: 'NA',
    email: '',
    sito: '',
    tel: '',
  },
  {
    field1: '14',
    cliente: 'Metalferramenta Spa',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Oberdan 64',
    cap: '33078',
    localita: 'S. Vito al T.',
    provincia: 'PN',
    email: 'info@metalferramenta.com',
    sito: 'www.metalferramenta.com',
    tel: '0434-875580',
  },
  {
    field1: '15',
    cliente: 'Angelini Professional Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. di Torrenova 435 A/B',
    cap: '00133',
    localita: 'Roma',
    provincia: 'RM',
    email: 'info@angeliniprofessional.com',
    sito: 'www.angeliniprofessional.com',
    tel: '06-2012604',
  },
  {
    field1: '16',
    cliente: 'CERIN Ingrosso Spa',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. O. da Pordenone 2',
    cap: '36100',
    localita: 'Vicenza',
    provincia: 'VI',
    email: 'info@ceriningrossospa.it',
    sito: 'www.ceriningrossospa.it',
    tel: '0444-922228',
  },
  {
    field1: '17',
    cliente: 'DI.V.A. S.A.S. di GPD Srl & C',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Po 31',
    cap: '66020',
    localita: 'S. Giovanni Teatino',
    provincia: 'CH',
    email: 'info@camel-diva.com',
    sito: 'www.camel-diva.com',
    tel: '085-4405221',
  },
  {
    field1: '18',
    cliente: 'FER-DOR Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Cooperazione 14',
    cap: '45100',
    localita: 'Rovigo',
    provincia: 'RO',
    email: 'ferdor@ferdor.it',
    sito: 'www.ferdor.it',
    tel: '0425-474501',
  },
  {
    field1: '19',
    cliente: 'FERBAN Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'via Paderno 3',
    cap: '25050',
    localita: 'Rodengo Saiano',
    provincia: 'BS',
    email: 'info@ferban.com',
    sito: 'www.ferban.com',
    tel: '030-611096',
  },
  {
    field1: '20',
    cliente: 'FERBOR Spa',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Cottolengo 24',
    cap: '10079',
    localita: 'Mappano',
    provincia: 'TO',
    email: 'ferbor@ferbor.it',
    sito: 'www.ferbor.it',
    tel: '011-9915311',
  },
  {
    field1: '21',
    cliente: 'FEREXPERT Spa',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Brini 2',
    cap: '40128',
    localita: 'Bologna',
    provincia: 'BO',
    email: 'info@ferexpert.it',
    sito: 'www.ferexpert.it',
    tel: '051-321121',
  },
  {
    field1: '22',
    cliente: 'FERRAMENTA CARNIMEO Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Napoli 331/A',
    cap: '70123',
    localita: 'Bari',
    provincia: 'BA',
    email: 'info@ferramentacarnimeo.it',
    sito: 'www.ferramentacarnimeo.it',
    tel: '080-5740340',
  },
  {
    field1: '23',
    cliente: 'FERRAMENTA CONTI Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. A. Einstein 35 / 46',
    cap: '50010',
    localita: 'Capalle Campi Bisenzio',
    provincia: 'FI',
    email: 'info@ferr-conti.it',
    sito: 'www.ferr-conti.it',
    tel: '055-8969684',
  },
  {
    field1: '24',
    cliente: 'FERSOMMA Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. G. Mazzini 55',
    cap: '21019',
    localita: 'Somma Lombardo',
    provincia: 'VA',
    email: 'info@fersomma.it',
    sito: 'www.fersomma.it',
    tel: '0331-769228',
  },
  {
    field1: '25',
    cliente: 'FERUTEC SARDA Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 's. s. 131 km 8,2',
    cap: '09028',
    localita: 'Sestu',
    provincia: 'CA',
    email: 'info@ferutecsarda.it',
    sito: 'www.ferutecsarda.it',
    tel: '070-2298175',
  },
  {
    field1: '26',
    cliente: 'FERUTEC Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Geirato 112 / 0 rosso',
    cap: '16138',
    localita: 'Genova',
    provincia: 'GE',
    email: 'vendite@ferutec.it',
    sito: 'www.ferutec.it',
    tel: '010-8364551',
  },
  {
    field1: '27',
    cliente: 'HOUSE SYSTEM Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Contrada Olivola Z.I. Snc',
    cap: '82100',
    localita: 'Benevento',
    provincia: 'BN',
    email: 'info@isiaccessori.it',
    sito: 'www.isiaccessori.it',
    tel: '0824-311598',
  },
  {
    field1: '28',
    cliente: 'MAXER Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Larga 29',
    cap: '47843',
    localita: 'Misano A.',
    provincia: 'RN',
    email: 'info@maxeronline.com',
    sito: 'www.maxeronline.com',
    tel: '0541-610008',
  },
  {
    field1: '29',
    cliente: 'MESTIERERIA RONCHINI Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'str. Teverina 56/A',
    cap: '01100',
    localita: 'Viterbo',
    provincia: 'VT',
    email: 'info@mestiereriaronchini.it',
    sito: 'www.mestiereriaronchini.it',
    tel: '0761-353179',
  },
  {
    field1: '30',
    cliente: 'OROBICA di Vari F. Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. C Bertoncina 33',
    cap: '24068ï¿½',
    localita: 'Seriate',
    provincia: 'BG',
    email: 'info@orobicadivari.it',
    sito: 'www.orobicadivari.it',
    tel: '035-296459',
  },
  {
    field1: '31',
    cliente: 'PIETRA Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Riscassi',
    cap: '29010',
    localita: 'S. Nicol a T.',
    provincia: 'PC',
    email: 'info@pc-pietra.it',
    sito: 'www.pc-pietra.it',
    tel: '0523-760941',
  },
  {
    field1: '32',
    cliente: 'PRINCIPE Srl',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Loc. Campo Contrada Melia',
    cap: '88060',
    localita: 'Argusto',
    provincia: 'CZ',
    email: 'info@principesrl.com',
    sito: 'www.principeaccessori.com',
    tel: '0967-9450',
  },
  {
    field1: '33',
    cliente: 'SERRONI GIOVANNI',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. P. Cotechini 84',
    cap: '63822',
    localita: 'Porto S. G.',
    provincia: 'FM',
    email: 'infobox@serronigiovanni.it',
    sito: 'www.serronigiovanni.it',
    tel: '0734-674879',
  },
  {
    field1: '34',
    cliente: 'U.F.I. di Oddone B. Sas',
    'tipologia-cliente': 'Partner',
    indirizzo: 'v. Nazionale 317',
    cap: '18100',
    localita: 'Imperia',
    provincia: 'IM',
    email: 'info@ufionline.it',
    sito: 'www.ufionline.it',
    tel: '0183-293780',
  },
  {
    field1: '36',
    cliente: 'Comune di Fagagna',
    'tipologia-cliente': 'Cliente',
    indirizzo: "Piazza Unit d'Italia, 3",
    cap: '33034',
    localita: 'Fagagna',
    provincia: 'UD',
    email: 'comune.fagagna@certgov.fvg.it',
    sito: 'https://www.comune.fagagna.ud.it/',
    tel: '432812111',
  },
  {
    field1: '37',
    cliente: 'Comune di San Canzian Dï¿½Isonzo',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Largo G. Garibaldi 37',
    cap: '34075',
    localita: 'Pieris',
    provincia: 'GO',
    email: 'comune.sancanziandisonzo@certgov.fvg.it',
    sito: 'http://www.comune.sancanziandisonzo.go.it/',
    tel: '0481 472 311',
  },
  {
    field1: '38',
    cliente: 'Comune di Mortegliano',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Piazza Verdi 10',
    cap: '33050',
    localita: 'Mortegliano',
    provincia: 'UD',
    email: 'comune.mortegliano@certgov.fvg.it',
    sito: 'http://www.comune.mortegliano.ud.it/',
    tel: '0432/826811',
  },
  {
    field1: '39',
    cliente: 'Comune di Rivignano Teor',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Piazza IV Novembre, 34',
    cap: '33061',
    localita: 'Rivignano Teor',
    provincia: 'UD',
    email: 'comune.rivignanoteor@certgov.fvg.it',
    sito: 'http://www.comune.rivignanoteor.ud.it/',
    tel: '0432 775778',
  },
  {
    field1: '40',
    cliente: 'Tribunale di Udine',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Largo Ospedale Vecchio, 1',
    cap: '33100',
    localita: 'Udine',
    provincia: 'UD',
    email: 'tribunale.udine@giustizia.it',
    sito: 'https://www.tribunaleudine.it/',
    tel: '0432 517911',
  },
  {
    field1: '41',
    cliente: 'Universit di Roma',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Piazzale Aldo Moro S.',
    cap: '.00185',
    localita: 'Roma',
    provincia: 'RM',
    email: '',
    sito: 'https://www.uniroma1.it/it/pagina-strutturale/home',
    tel: '',
  },
  {
    field1: '42',
    cliente: 'Convitto Salesiani San Luigi',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Via Arcivescovado, 2',
    cap: '34170',
    localita: 'Gorizia',
    provincia: 'GO',
    email: 'cancelleria@arcidiocesi.gorizia.it',
    sito: 'http://www.gorizia.chiesacattolica.it/',
    tel: '481597617',
  },
  {
    field1: '43',
    cliente: 'Tech-Masters Italia Srl',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Via San Bartolomeo, 51',
    cap: '21040',
    localita: 'Carnago',
    provincia: 'VA',
    email: 'info@tech-masters.it',
    sito: 'https://www.tech-masters.com/it',
    tel: '331993313',
  },
  {
    field1: '44',
    cliente: 'Anfit',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Viale Cavour, 116',
    cap: '44121',
    localita: 'Ferrara',
    provincia: 'FE',
    email: 'mariotto@anfit.it',
    sito: 'http://www.anfit.it/',
    tel: '532473492',
  },
  {
    field1: '45',
    cliente: 'Poste Italiane',
    'tipologia-cliente': 'Cliente',
    indirizzo: 'Viale Europa, 190',
    cap: '00144',
    localita: 'ROMA',
    provincia: 'RM',
    email: 'poste@pec.posteitaliane.it',
    sito: 'www.poste.it',
    tel: '0659581',
  },
  {
    field1: '46',
    cliente: 'Camaleante',
    'tipologia-cliente': 'Partner',
    indirizzo: 'Via Jacopo Linussio, 51',
    cap: '33100',
    localita: 'Mortegliano',
    provincia: 'UD',
    email: 'info@camaleante.com',
    sito: 'www.camaleante.com',
    tel: '0432629779',
  },
];

export const imgForum = [
  forum1,
  forum2,
  forum3,
  forum4,
  forum5,
  forum6,
  forum7,
  forum8,
];
