import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { AiOutlineFilePdf } from 'react-icons/ai';
import statistica from '../assets/stat_finale_angel.pdf';
import libro1 from '../assets/Angelocks_libro_n.1.pdf';
import libro2 from '../assets/angelocks_libro2.pdf';
import ferramenta from '../assets/problema_ferramenta.pdf';
import inc1 from '../assets/images/inc1.png';
import inc2 from '../assets/images/inc2.png';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function Incidenti() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='overflow-hidden'
    >
      <div className='bg-black border-8 border-dashed border-yellow-400'>
        <ReactPlayer
          width={'100%'}
          height={600}
          url='https://vimeo.com/391191747'
          controls={true}
          autoPlay={true}
        />
      </div>
      <div className='container mx-auto px-3'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Incidenti
        </div>
        <div className='sm:flex justify-between mb-8'>
          <div className='mb-5'>
            <p className='mb-5'>
              Vedi tutti gli incidenti senza l'utilizzo dell'accessorio di
              sicurezza Angelocks.
            </p>
            <p className='mb-5'>
              Guarda alcuni incidenti presenti sul nostro canale
              <a
                className='text-blue-500 hover:underline'
                href='https://www.youtube.com/channel/UCtq22UJrZNFI7MI-lczsJCw/featured'
              >
                YouTube
              </a>
            </p>
            <p>
              Gli incidenti avvenuti nel mondo da noi registrati al giorno
              09/04/2021 sono <span className='font-bold'>720</span>
            </p>
          </div>
          <div className='grid md:grid-cols-2 gap-3 text-center'>
            <a
              href={statistica}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Statistica degli incidenti
            </a>
            <a
              href={ferramenta}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Problema produttore ferramenta
            </a>
            <a
              href={libro1}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Tutti gli incidenti nel mondo da noi rilevati pt. 1
            </a>
            <a
              href={libro2}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Tutti gli incidenti nel mondo da noi rilevati pt. 2
            </a>
          </div>
        </div>
        <div className='mb-10 md:flex justify-evenly space-y-3 md:space-y-0'>
          <div className='flex flex-col items-center space-y-5'>
            <img className='w-2/3 rounded-2xl' src={inc1} alt='' />

            <div className='transform duration-300 hover:-translate-y-1 rounded-full overflow-hidden inline-block'>
              <Link
                className='uppercase bg-red-600 duration-300 hover:bg-red-500 text-white py-5 px-8 block sm:inline-block text-center'
                to='/incidenti/italia'
              >
                Vedi alcuni incidenti in Italia
              </Link>
            </div>
          </div>
          <div className='flex flex-col items-center space-y-5'>
            <img className='w-2/3 rounded-2xl' src={inc2} alt='' />

            <div className='transform duration-300 hover:-translate-y-1 rounded-full overflow-hidden inline-block'>
              <Link
                className='uppercase bg-blue-600 duration-300 hover:bg-blue-500 text-white py-5 px-8 block sm:inline-block text-center'
                to='/incidenti/estero'
              >
                Vedi alcuni incidenti all'estero
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Incidenti;
