import React from 'react';
import banner from '../assets/images/bg.jpeg';
//Components
import Intro from '../components/home/Intro';
import Media from '../components/home/Media';
import Incidente from '../components/home/Incidente';
import News from '../components/home/News';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function Home() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <img className='w-full' src={banner} alt='banner' />
      <Intro />
      <Media />
      <Incidente />
      <News />
    </motion.div>
  );
}

export default Home;
