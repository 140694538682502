import React from 'react';
import { proveAngelocks } from '../utils/data';
import ReactPlayer from 'react-player';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function ProveInterne() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='overflow-hidden'
    >
      <div className='bg-black'>
        <ReactPlayer
          width={'100vw'}
          url='https://vimeo.com/387677359'
          controls={true}
          autoPlay={true}
        />
      </div>

      <div className='container mx-auto px-3 pt-8'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Prove Interne
        </div>
        <p className='text-2xl'>
          La fase di sviluppo dell'accessorio ha comportato una verifica
          puntuale di tutte le parti costituenti Angelocks. Sono state
          effettuate più di 5000 prove interne.
        </p>
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-3 py-10'>
          {proveAngelocks[0].img.map((img) => (
            <div className='overflow-hidden rounded-xl'>
              <img src={img} alt='' />
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}

export default ProveInterne;
