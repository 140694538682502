import React from 'react';
import { NavLink } from 'react-router-dom';

function DesktopNav({ navigation }) {
  return (
    <div className='hidden xl:block'>
      <nav className='flex space-x-6'>
        {navigation.map((nav) => (
          <NavLink
            className='flex-shrink-0'
            exact
            activeClassName='isActive'
            to={nav.path}
            key={nav.id}
          >
            {nav.route}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

export default DesktopNav;
