import React from 'react';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';
//Images
import img1 from '../assets/images/imgNew1.jpeg';
import img2 from '../assets/images/imgNew2.jpeg';
import img3 from '../assets/images/imgNew3.jpeg';
import img4 from '../assets/images/imgNew4.jpeg';

function News() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto pt-10 px-3 text-xl overflow-hidden mb-10'
    >
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mb-10'>
        Angelocks come limitatore di apertura
      </div>
      <div className='my-10'>
        <p>
          In aggiunta alla funzione anti caduta, abbiamo studiato anche una
          seconda applicazione che vi permetterà di limitare l’apertura della
          vostra finestra.
        </p>
      </div>
      <div className='grid md:grid-cols-2 gap-3'>
        <img className='rounded-lg' src={img4} alt='' />
        <img className='rounded-lg' src={img2} alt='' />
        <img className='rounded-lg' src={img3} alt='' />
        <img className='rounded-lg' src={img1} alt='' />
      </div>
    </motion.div>
  );
}

export default News;
