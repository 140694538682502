import React from 'react';
import { Link } from 'react-router-dom';
import incidente from '../../assets/images/foto_finestra.jpeg';

function Incidente() {
  return (
    <div className='container mx-auto px-3 py-5'>
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10 '>
        <Link to='/incidenti'>Incidenti</Link>
      </div>
      <div className='flex flex-col md:flex-row justify-between items-center py-5'>
        <div className='space-y-4 text-xl mb-5 md:mb-0'>
          <p>Hotel Mira ad Hong Kong.</p>
          <p>
            Prima di Angelocks questo caso si poteva definire incidente dopo
            Angelocks no.
          </p>
          <p className='font-bold'>Angelocks avrebbe evitato questa morte.</p>
        </div>
        <div className='h-60 w-80 overflow-hidden rounded-xl'>
          <img className='object-cover' src={incidente} alt='hong-kong' />
        </div>
      </div>
      <div className='sm:flex justify-evenly space-y-3 sm:space-y-0 sm:space-x-4'>
        <div className='transform duration-300 hover:-translate-y-1 rounded-full overflow-hidden inline-block'>
          <Link
            className='uppercase bg-red-600 duration-300 hover:bg-red-500 text-white py-5 px-8 block sm:inline-block text-center'
            to='/incidenti/italia'
          >
            Vedi alcuni incidenti in Italia
          </Link>
        </div>
        <div className='transform duration-300 hover:-translate-y-1 rounded-full overflow-hidden'>
          <Link
            className='uppercase bg-blue-600 duration-300 hover:bg-blue-500 text-white py-5 px-8 block sm:inline-block text-center'
            to='/incidenti/estero'
          >
            Vedi alcuni incidenti all'estero
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Incidente;
