import React from 'react';
import ReactPlayer from 'react-player';
import { images } from '../../utils/data';

function Media() {
  return (
    <div className='px-5 bg-blue-800 text-white text-center overflow-hidden pb-10'>
      <div className='flex flex-wrap gap-5 md:justify-evenly justify-center mb-10'>
        <div>
          <ReactPlayer
            width={450}
            url='https://vimeo.com/387676481'
            controls={true}
          />
          <p className='font-semibold text-lg'>
            Scopri Angelocks guardando questo video!
          </p>
        </div>
        <div>
          <ReactPlayer
            width={450}
            url='https://vimeo.com/387911203'
            controls={true}
          />
          <p className='font-semibold text-lg'>
            Discover Angelocks by watching this video!
          </p>
        </div>
      </div>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-3'>
        {images.map((img) => (
          <div className='overflow-hidden rounded-lg' key={img.id}>
            <img
              className='h-80 w-full object-cover'
              src={img.src}
              alt={img.alt}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Media;
