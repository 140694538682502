import React from 'react';
import NewsCard from '../components/incidenti/NewsCard';
import { incidenti } from '../utils/data';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function IncidentiItalia() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto px-3'
    >
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
        Incidenti avvenuti in Italia
      </div>
      <div className='py-10 grid sm:grid-cols-2 md:grid-cols-3 gap-3'>
        {incidenti.map((news) => (
          <NewsCard
            img={news.img}
            desc={news.desc}
            link={news.src}
            key={news.id}
          />
        ))}
      </div>
    </motion.div>
  );
}

export default IncidentiItalia;
