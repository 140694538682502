import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookSquare, FaTwitterSquare, FaYoutube } from 'react-icons/fa';

function TheFooter() {
  return (
    <div className='divide-y-2'>
      <footer className='flex flex-col md:flex-row justify-evenly text-white bg-blue-900 py-10 cursor-default space-y-5 md:space-y-0'>
        <div className='text-center'>
          <h1 className='uppercase font-bold text-xl'>Serrametal srl</h1>
          <p>Via Buiatti 40</p>
          <p>33050 Mortegliano (UD)</p>
        </div>

        <div>
          <h1 className='uppercase text-center mb-2'>Seguici sui social</h1>
          <div className='flex text-4xl justify-around'>
            <a
              href='https://it-it.facebook.com/Angelocks1/'
              target='_blank'
              rel='noreferrer'
            >
              <FaFacebookSquare />
            </a>
            <a
              href='https://twitter.com/angelocks1'
              target='_blank'
              rel='noreferrer'
            >
              <FaTwitterSquare />
            </a>
            <a
              href='https://www.youtube.com/channel/UCtq22UJrZNFI7MI-lczsJCw'
              target='_blank'
              rel='noreferrer'
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </footer>

      <div className='bg-gray-900 text-white text-center w-full p-4 space-y-2 flex flex-col items-center'>
        <div className='flex flex-wrap justify-center'>
          <p className='text-center'>
            Serrametal Srl - Via Buiatti 40 / S.R. 353 33050 Mortegliano
          </p>
          <p className='text-center'>(UD) - CF/P.IVA 00236760302</p>
        </div>
        <p>
          serrametal@pec.serrametal.com - REA UD-34837 - Cap. Sociale
          €115.000,00
        </p>
        <Link to='privacy' className='text-lg hover:underline'>
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}

export default TheFooter;
