import React from 'react';
import contact from '../assets/images/contact-sec.jpeg';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function Contatti() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto'
    >
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10 ml-3'>
        Contattaci
      </div>
      <iframe
        title='map'
        src='https://www.google.com/maps/d/u/0/embed?mid=1hlA8YRk-qHF5SeUKM6_APC5mkE1_BPfh'
        width='100%'
        height='450'
      ></iframe>
      <div className='w-full mt-10 flex flex-col items-center mb-5'>
        <p className='font-bold text-2xl text-center pb-5'>
          Riconosci serramenti che utilizzano già Angelocks
        </p>

        <img
          src={contact}
          alt='angelocks'
          className='w-full h-80 sm:w-2/3 lg:w-1/3 object-cover'
        />
        <p className='mt-5 font-semibold text-center px-3'>
          Azienda Certificata UNI EN ISO 9001, UNI EN ISO 1090, UNI EN ISO 3834
        </p>
      </div>

      <div className='sm:flex justify-evenly'>
        <div className='flex flex-col items-center my-3'>
          <p className='text-xl font-bold mb-3 inline-block'>Serrametal Srl</p>
          <p>Via Buiatti 40</p>
          <p>33050 Mortegliano (UD)</p>
          <a
            className='bg-blue-900 text-white p-3 rounded-xl shadow-md my-2'
            href='mailto:marketing@serrametal.com'
          >
            marketing@serrametal.com
          </a>
          <a
            className='bg-blue-900 text-white p-3 rounded-xl shadow-md mb-2'
            href='tel:0432760086'
          >
            0432 760086
          </a>
        </div>
      </div>
      <div>
        <div className='text-center mt-10'>
          <p className='text-2xl font-medium px-3'>
            Richiedi un preventivo o maggiori informazioni sul dispositivo
            Angelocks. Il nostro staff ti risponderà il prima possibile
          </p>
          <div className='bg-red-600 hover:bg-red-500 transform duration-300 hover:-translate-y-1 text-white inline-block rounded-full my-10 hover:shadow-lg'>
            <a
              className='py-5 px-10  inline-block uppercase'
              href='mailto:marketing@serrametal.com'
            >
              richiedi un preventivo
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Contatti;
