import React from 'react';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function Privacy() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto text-xl space-y-4 px-5'
    >
      <p className='font-bold'>INFORMATIVA PRIVACY</p>
      <p>
        INFORMATIVA ai sensi dell'art. 13 del Regolamento UE 679/2016 (“GDPR”).
        Nell'osservanza del Codice e del GDPR, Camaleante S.r.l, come meglio
        individuata in seguito, in qualità di "Titolare" del trattamento, Le
        fornisce alcune informazioni circa l'utilizzo degli eventuali dati
        personali conferiti dagli utenti che consultano e/o interagiscono con il
        sito.
      </p>
      <h1>Finalità del trattamento dei dati</h1>
      <p>
        L'utente è libero di fornire i suoi dati personali per l'invio del
        proprio curriculum vitae, per sollecitare l'invio di materiale
        informativo o di altre comunicazioni. Il trattamento dei dati personali
        potrà essere effettuato unicamente per rispondere alle richieste
        pervenute, ai sensi dell’art. 6.1, lett. b) del GDPR. Il conferimento di
        tali dati è facoltativo, ma il mancato conferimento degli stessi può
        comportare l'impossibilità di ottenere quanto richiesto.
      </p>
      <h1>Tipologia di dati trattati</h1>
      <p>
        Al fine di dar seguito alle richieste pervenute tramite il sito internet
        tratteremo i dati di contatto inseriti nell'eventuale apposito form
        dall'interessato. Saranno inoltre trattati i Suoi dati identificativi
        raccolti al momento della registrazione sul Sito.
      </p>
      <h1>Modalità di trattamento dei dati</h1>
      <p>
        Il trattamento dei dati personali avviene mediante strumenti manuali,
        informatici e telematici e in modo da garantire la sicurezza e la
        riservatezza dei dati stessi. Vengono utilizzati sistemi di prevenzione
        e protezione, costantemente aggiornati e verificati in termini di
        affidabilità.{' '}
      </p>
      <h1>Categorie di soggetti ai quali i dati possono essere comunicati </h1>
      <p>
        Per il perseguimento delle finalità di cui sopra, Camaleante S.r.l
        necessita di comunicare i dati personali ai propri "incaricati" ed a
        soggetti esterni "responsabili". L’elenco completo dei Responsabili è
        disponibile presso la sede del Titolare. Si precisa, in particolare, che
        tali dati non saranno diffusi e saranno oggetto di trattamento da parte
        di soggetti debitamente incaricati all'assolvimento di tali compiti,
        costantemente identificati e/o nominati, opportunamente istruiti e resi
        edotti dai vincoli imposti dalla legge, nonché mediante l’impiego di
        misure di sicurezza atte a garantire la tutela della Sua riservatezza e
        ad evitare i rischi di perdita o distruzione, di accessi non
        autorizzati, di trattamenti non consentiti o non conformi alle finalità
        di cui sopra.
      </p>
      <h1>Durata del trattamento e conservazione dei dati personali</h1>
      <p>
        I Suoi dati personali saranno trattati: relativamente ai dati
        anagrafici, di contatto e di pagamento: per il periodo di tempo
        necessario ad adempiere al contratto di vendita del prodotto/servizio e
        per il periodo di tempo richiesto dalla normativa applicabile (in
        particolare, dieci anni per i tempi di conservazione della
        documentazione contrattuale e fiscale) nonché fino all’esaurimento delle
        obbligazioni contrattuali intercorse tra i Titolari. per un periodo di
        12 (dodici mesi) relativamente a quelli inerenti a comportamenti di
        navigazione sul Sito e su altri siti Internet, alle Sue abitudini di
        consumo, all’area geografica ed al dispositivo di collegamento al sito,
        all’indirizzo IP; b) per un periodo di 24 (ventiquattro) mesi quelli
        necessari allo svolgimento di attività di marketing. In entrambe i casi
        resta ferma la possibilità dell’interessato di opporsi in ogni momento
        al trattamento, revocando il consenso prestato. Si precisa che i dati
        non sono utilizzati al fine dell’adozione di processi decisionali
        automatizzati.
      </p>
      <h1>Titolare del trattamento </h1>
      <p>
        Titolare del trattamento è Camaleante S.r.l, con sede legale in Via
        Jacopo Linussio 51 33100 Udine (UD), C.F. e P.I.V.A. 02862590300, email
        info@serrametal.com
      </p>
      <h1>Diritti degli interessati </h1>
      <ul className='list-disc'>
        Ai sensi dell’art. 7 del Codice Privacy e degli artt. 15 e ss. del GDPR,
        Lei ha il diritto di ottenere:
        <li>
          la conferma dell'esistenza o meno di dati personali che La riguardano,
          anche se non ancora registrati, e la loro comunicazione in forma
          intelligibile;
        </li>
        <li>una copia dei Suoi dati personali;</li>
        <li>la cancellazione dei Suoi dati personali;</li>
        <li>la limitazione del trattamento dei Suoi dati personali; </li>
        <li>
          n un formato strutturato, di uso comune e leggibile da dispositivo
          automatico i dati personali che Lei ci ha fornito o che Lei stesso ha
          creato – esclusi i giudizi creati dal Titolare e/o dagli incaricati ex
          art. 4 del Codice Privacy / dalle persone autorizzate a trattare i
          dati a nome e per conto del Titolare ex art. 4 del GDPR - e di
          trasmetterli, direttamente o per mezzo del Titolare, ad un altro
          titolare del trattamento;
        </li>
      </ul>
      <ul className='list-disc'>
        L'indicazione:
        <li>dell'origine dei dati personali; </li>
        <li>delle categorie di dati personali trattati; </li>
        <li>
          delle finalità e modalità del trattamento;della logica applicata in
          caso di trattamento effettuato con l'ausilio di strumenti elettronici;
        </li>
        <li>
          della logica applicata in caso di trattamento effettuato con l'ausilio
          di strumenti elettronici;
        </li>
        <li>
          degli estremi identificativi del Titolare e degli eventuali
          responsabili;
        </li>
        <li>
          del periodo di conservazione dei Suoi dati personali o dei criteri
          utili per la determinazione di tale periodo;
        </li>
        <li>
          dei soggetti o delle categorie di soggetti ai quali i dati personali
          possono essere comunicati o che possono venirne a conoscenza in
          qualità di rappresentante designato nel territorio dello Stato, di
          responsabili o di incaricati ex art. 4 del Codice Privacy / persone
          autorizzate a trattare i dati a nome e per conto del Titolare ex art.
          4 del GDPR;
        </li>
        <li>
          l'aggiornamento, la rettificazione ovvero, quando Vi ha interesse,
          l'integrazione dei dati;
        </li>
        <li>
          la trasformazione in forma anonima o il blocco dei dati trattati in
          violazione di legge, compresi quelli di cui non è necessaria la
          conservazione in relazione agli scopi per i quali i dati sono stati
          raccolti o successivamente trattati;
        </li>
        <li>
          l'attestazione che le operazioni di cui alle lettere a) e b) sono
          state portate a conoscenza, anche per quanto riguarda il loro
          contenuto, di coloro ai quali i dati sono stati comunicati o diffusi,
          eccettuato il caso in cui tale adempimento si rivela impossibile o
          comporta un impiego di mezzi manifestamente sproporzionato rispetto al
          diritto tutelato.
        </li>
      </ul>
      <ul className='list-disc'>
        Lei, inoltre, ha diritto di opporsi, in tutto o in parte:
        <li>
          per motivi legittimi, al trattamento dei dati personali che La
          riguardano, ancorché pertinenti allo scopo della raccolta;
        </li>
        <li>
          al trattamento di dati personali che La riguardano a fini di invio di
          materiale pubblicitario o di vendita diretta o per il compimento di
          ricerche di mercato o di comunicazione commerciale.
        </li>
      </ul>
      <p>
        Per esercitare i suddetti diritti, può inviare una comunicazione AL
        SEGUENTE INDIRIZZO: Camaleante Srl - Via Jacopo Linussio 51 33100 Udine
        (UD) indicando in oggetto "Privacy - esercizio dei diritti ex art. 7 del
        D.Lgs. 196/2003 ed ex artt. 15 e ss. del GDPR". La informiamo, da
        ultimo, che qualora ritenga che i Suoi diritti siano stati violati dal
        Titolare e/o da un terzo, Lei ha il diritto di proporre reclamo al
        Garante per la Protezione dei Dati Personali e/o ad altra autorità di
        controllo competente in forza del GDPR.
      </p>
    </motion.div>
  );
}

export default Privacy;
