import React from 'react';
import NewsCard from '../../components/incidenti/NewsCard';
import { newsHome } from '../../utils/data';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import news1 from '../../assets/images/imgNew1.jpeg';

function News() {
  return (
    <div className='bg-blue-100'>
      <div className='container mx-auto pt-10 px-3'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mb-10'>
          News
        </div>
        <div className='py-10  grid sm:grid-cols-2 lg:grid-cols-3 gap-3 px-3'>
          <div className='overflow-hidden rounded-xl shadow-lg flex flex-col justify-between flex-auto bg-white'>
            <div>
              <img
                className='h-52 w-full object-cover'
                src={news1}
                alt='copertina'
              />

              <p className='pb-5 px-2 pt-2'>
                ANGELOCKS COME LIMITATORE DI APERTURA
              </p>
            </div>
            <div className='uppercase py-3 px-6 bg-red-600 text-white block text-center rounded-xl m-2 transform duration-300 hover:-translate-y-1 hover:bg-red-500'>
              <Link to='/news-1'>Scopri di più</Link>
            </div>
          </div>

          {newsHome
            .slice(0, 4)
            .map(
              (news) =>
                news.img && (
                  <NewsCard
                    img={news.img}
                    link={news.link}
                    key={news.id}
                    desc={news.desc}
                  />
                )
            )}
          <div className='overflow-hidden rounded-xl shadow-lg flex flex-col justify-between flex-auto bg-white'>
            <div>
              <img
                className='h-52 w-full object-cover'
                src={newsHome[4].img}
                alt='copertina'
              />

              <p className='pb-5 px-2 pt-2'>{newsHome[4].desc}</p>
            </div>
            <div className='uppercase py-3 px-6 bg-red-600 text-white block text-center rounded-xl m-2 transform duration-300 hover:-translate-y-1 hover:bg-red-500'>
              <Link to={newsHome[4].link}>Scopri di più</Link>
            </div>
          </div>
          <div className='overflow-hidden rounded-xl shadow-lg flex flex-col justify-between flex-auto bg-white'>
            <div>
              <img
                className='h-52 w-full object-cover'
                src={newsHome[5].img}
                alt='copertina'
              />

              <p className='pb-5 px-2 pt-2'>{newsHome[5].desc}</p>
            </div>
            <div className='uppercase py-3 px-6 bg-red-600 text-white block text-center rounded-xl m-2 transform duration-300 hover:-translate-y-1 hover:bg-red-500'>
              <a
                href={newsHome[5].link}
                rel='noreferrer'
                target='_blank'
                to={newsHome[5].link}
              >
                Scopri di più
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
