import React from 'react';
import ReactPlayer from 'react-player';
import { catImages } from '../utils/data';
import danger from '../assets/images/danger.jpeg';
//Download files
import eng from '../assets/angelocks_eng.pdf';
import ita from '../assets/angelocks_ita.pdf';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';
//
import italy from '../assets/images/italy.png';
import uk from '../assets/images/uk.png';

function Cataloghi() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='overflow-hidden'
    >
      <div className='bg-black'>
        <ReactPlayer
          width={'100%'}
          url='https://vimeo.com/388967960'
          controls={true}
          autoPlay={true}
        />
      </div>
      <div className='container mx-auto px-3'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Cataloghi
        </div>
        <div>
          <div className='grid md:grid-cols-3 gap-3'>
            {catImages.map((img) => (
              <div className='overflow-hidden rounded-lg' key={img.id}>
                <img
                  className='h-80 w-full object-cover'
                  src={img.src}
                  alt={img.alt}
                />
              </div>
            ))}
          </div>
          <p className='text-xl text-center mb-10'>Dispositivo Angelolocks.</p>
        </div>
        <div>
          <div
            className='h-96 overflow-hidden bg-no-repeat bg-center bg-fill'
            style={{ backgroundImage: `url(${danger})` }}
          ></div>
          <p className='text-xl text-center mb-10'>
            Dispositivi non certificati, non sicuri e costosi.
          </p>
        </div>
      </div>
      <div className='container mx-auto px-3'>
        <div className='flex flex-col lg:flex-row lg:justify-evenly items-center divide-y-2 lg:divide-y-0 space-y-4 lg:space-y-0 lg:mb-10'>
          <div className='flex flex-col items-center px-3 w-screen'>
            <p className='mb-5 uppercase text-xl'>
              Scarica il catalogo in PDF in italiano
            </p>
            <a href={ita} download>
              <img
                className='h-28 transform duration-300 hover:scale-125'
                src={italy}
                alt=''
              />
            </a>
            <ReactPlayer
              width={400}
              url='https://vimeo.com/388967960'
              controls={true}
              responsive={'100%'}
            />
            <p className='text-red-700 text-center text-2xl transform -translate-y-8 font-semibold'>
              Scopri Angelocks guardando questo video!
            </p>
          </div>
          <div className='flex flex-col items-center w-screen'>
            <p className='mb-5 md:mt-0 uppercase text-xl'>
              download the pdf in english
            </p>

            <a href={eng} download>
              <img
                className='h-28 transform duration-300 hover:scale-125'
                src={uk}
                alt=''
              />
            </a>
            <ReactPlayer
              url='https://player.vimeo.com/video/388967902'
              controls={true}
              width={400}
            />
            <p className='text-red-700 text-center text-2xl px-1 mb-5 lg:mb-0 transform -translate-y-8 font-semibold'>
              Discover Angelocks by watching this video!
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Cataloghi;
