import React from 'react';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';
// Images

import img0 from '../assets/images/image0.jpeg';
import img1 from '../assets/images/image1.jpeg';
import img2 from '../assets/images/image2.jpeg';
import img3 from '../assets/images/image3.jpeg';
import img5 from '../assets/images/image5.jpeg';
import img6 from '../assets/images/image6.jpeg';
import img7 from '../assets/images/image7.jpeg';
import img8 from '../assets/images/image8.jpeg';

function Personalizza() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto pt-10 px-3 text-xl overflow-hidden mb-10'
    >
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mb-10'>
        Angelocks Special
      </div>
      <div className='space-y-10'>
        <div>
          <p className='text-4xl font-semibold mb-8'>
            I Nostri Angelocks Special
          </p>
          <div className='overflow-hidden rounded-2xl'>
            <img src={img0} alt='' />
          </div>
        </div>
        <div>
          <p className='text-2xl md:text-4xl font-semibold mb-5'>
            Angelocks Special Blocca Finestra
          </p>
          <div className='grid md:grid-cols-2 gap-5'>
            <div className='overflow-hidden rounded-2xl'>
              <img className='transform rotate-180' src={img1} alt='' />
            </div>
            <div className='overflow-hidden rounded-2xl'>
              <img src={img2} alt='' />
            </div>
          </div>
        </div>
        <div>
          <p className='text-2xl md:text-4xl font-semibold mb-5'>
            Angelocks Special Legno
          </p>
          <div className='grid md:grid-cols-2 gap-5'>
            <div className='overflow-hidden rounded-2xl'>
              <img src={img5} alt='' />
            </div>
            <div className='overflow-hidden rounded-2xl'>
              <img src={img6} alt='' />
            </div>
          </div>
        </div>
        <div>
          <p className='text-2xl md:text-4xl font-semibold mb-5'>
            Angelocks Special Verniciato
          </p>
          <div className='grid md:grid-cols-2 gap-5'>
            <div className='overflow-hidden rounded-2xl'>
              <img src={img3} alt='' />
            </div>
            <div className='overflow-hidden rounded-2xl'>
              <img src={img7} alt='' />
            </div>
          </div>
        </div>
        <div>
          <p className='text-2xl md:text-4xl font-semibold mb-5'>
            Angelocks Special Extralungo
          </p>
          <div className='overflow-hidden rounded-2xl'>
            <img src={img8} alt='' />
          </div>
        </div>

        <ul className='text-xl pl-5'>
          Offriamo:
          <li className='list-disc'>
            Personalizzazione con nome o logo azienda
          </li>
          <li className='list-disc'>Spedizione in tutto il mondo</li>
        </ul>

        <div className='font-bold text-2xl md:text-3xl border-l-4 border-blue-900 pl-5 mt-5 '>
          <p>
            Se anche tu vuoi il tuo Angelocks Special contattaci a&nbsp;
            <a
              className='text-blue-500 hover:underline'
              href='mailto:marketing@serrametal.com'
            >
              marketing@serrametal.com
            </a>
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default Personalizza;
