import React from 'react';
import logo from '../../assets/images/logo.png';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import { Link } from 'react-router-dom';
import { nav } from '../../utils/data';

function Navigation() {
  return (
    <div className='container mx-auto px-3'>
      <header className='flex items-center py-2 justify-between'>
        <Link to='/'>
          <img className='h-20 flex-shrink-0' src={logo} alt='angelocks-logo' />
        </Link>

        <MobileNav navigation={nav} />
        <DesktopNav navigation={nav} />
      </header>
    </div>
  );
}

export default Navigation;
