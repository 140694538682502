import React from 'react';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';
// Immagini
import img0 from '../assets/images/imbal0.jpeg';
import img1 from '../assets/images/imbal1.jpeg';
import qr from '../assets/images/qr.jpg';

function DaNoi() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto pt-10 px-3 text-xl overflow-hidden mb-10'
    >
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mb-10'>
        <p>Qualità testata e certificata.</p>
        <p>Da noi per Voi.</p>
      </div>

      <div className='space-y-3'>
        <div className='overflow-hidden rounded-2xl md:w-1/2'>
          <img src={img0} alt='' />
        </div>
        <p>
          I nostri Angelocks vengono curati nell’imballaggio. Le confezioni, da
          100 pezzi, sono costituite da solido cartone ondulato, riciclato e
          riciclabile.
        </p>
        <p>
          All’interno della confezione esterna si trovano 10 confezioni
          contenenti ognuna 10 Angelocks ciascuna.
        </p>
      </div>
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 my-10'>
        La Nostra Qualità . . . La Vostra Sicurezza
      </div>

      <div className='space-y-4'>
        <p>
          &bull; Al momento dell’arrivo in azienda, ogni pezzo, componente
          Angelocks, è certificato, controllato e pesato al fine di garantire
          una qualità costante nel tempo.
        </p>
        <p>
          &bull; Ogni 100 prodotti finiti, l’addetto specializzato, esegue una
          prova di rottura, con un macchinario computerizzato, appositamente
          costruito.
        </p>
        <p>
          &bull; Vengono inoltre effettuati scrupolosi controlli visivi ed
          utilizzando un liquido penetrante apposito.
        </p>
        <p>
          &bull; Per Angelocks sono previsti test di qualità ed utilizzo di
          materiali certificati.
        </p>
        <p>
          &bull; I nostri operai sono specializzati e garantiscono una continua
          qualità del prodotto finito.
        </p>
        <p>
          &bull; Azienda Certificata UNI EN ISO 9001, UNI EN ISO 1090, UNI EN
          ISO 3834.
        </p>
      </div>
      <div className='flex justify-between items-center'>
        <div className='overflow-hidden rounded-2xl md:w-1/2 my-10'>
          <img src={img1} alt='' />
        </div>
        <div className='overflow-hidden rounded-2xl md:w-1/2 my-10'>
          <img src={qr} alt='' />
        </div>
      </div>
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 my-10'>
        Come riconoscere un prodotto originale e di qualità?
      </div>

      <div>
        <p>
          Nell’ultima fase della produzione, ogni Angelocks viene munito di un
          codice QR integrato che si compone di un sistema codificato in grado
          di riconoscere l’originalità del prodotto e garantire la prestazione
          certificata dell’accessorio.
        </p>
      </div>
      <div>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 my-10'>
          Anche Angelocks abbraccia l’ambiente
        </div>
        <p>
          La produzione di Angelocks viene interamente svolta in modo
          Eco-Friendly.
        </p>
        <p>
          Lo sfruttamento dell’impianto fotovoltaico e l’utilizzo di materiali
          riciclabili (caratterizzati da una lunga durata) garantisce un consumo
          di CO2 molto basso.
        </p>
      </div>
    </motion.div>
  );
}

export default DaNoi;
