import React from 'react';

function PartnerTable({ data }) {
  return (
    <div className='my-5'>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Cliente
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Tipologia
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Indirizzo
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      CAP
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Località
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Provincia
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Email
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Sito
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Telefono
                    </th>
                  </tr>
                </thead>

                <tbody className='bg-white divide-y divide-gray-200'>
                  {data.map((c) => (
                    <tr key={c.field1}>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {c.cliente}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {c['tipologia-cliente']}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {c.indirizzo}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {c.cap}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-left text-sm font-medium'>
                        {c.localita}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-left text-sm font-medium'>
                        {c.provincia}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-left text-sm font-medium'>
                        {c.email}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-left text-sm font-medium'>
                        <a href={c.sito} rel='noreferrer' target='_blank'>
                          {c.sito}
                        </a>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-left text-sm font-medium'>
                        {c.tel}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerTable;
