export const menuSlide = {
  hidden: {
    x: '50vw',
  },
  open: {
    x: 0,
  },
  close: {
    x: '50vw',
  },
  transition: { ease: 'easeOut' },
};
export const tendinaSlide = {
  hidden: {
    opacity: 0,
    y: -20,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
  close: {
    opacity: 0,
    y: -20,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
};

export const pageTransition = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
      when: 'beforeChildren',
      staggerChildren: 0.25,
    },
  },
  exit: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 0.5,
    },
  },
};
