import React from 'react';

function NewsCardBlue({ desc, img, link }) {
  return (
    <div className='overflow-hidden rounded-xl shadow-lg flex flex-col justify-between flex-auto bg-white'>
      <div>
        <img className='h-52 w-full object-cover' src={img} alt='copertina' />

        <p className='pb-5 px-2 pt-2'>{desc}</p>
      </div>
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className='uppercase py-3 px-6 bg-blue-600 text-white block text-center rounded-xl m-2 transform duration-300 hover:-translate-y-1 hover:bg-blue-500'
      >
        Scopri di più
      </a>
    </div>
  );
}

export default NewsCardBlue;
