import React, { useState, useEffect } from 'react';
import { clienti } from '../utils/data';
import PartnerTable from '../components/PartnerTable';
import SelettoreProvince from '../components/SelettoreProvince';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function Partner() {
  const [data, setData] = useState(clienti);
  const [prov, setProv] = useState('');

  useEffect(() => {
    if (prov === 'tutte') {
      setData(clienti);
    } else if (prov === '') {
      setData(clienti);
    } else {
      setData(clienti.filter((c) => c.provincia === prov));
    }
  }, [prov]);

  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='container mx-auto pt-10 px-3 min-h-screen'
    >
      <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mb-10'>
        I Nostri Partner
      </div>
      <div className='mb-10'>
        <SelettoreProvince setProv={setProv} data={clienti} />
      </div>
      <PartnerTable data={data} />
    </motion.div>
  );
}

export default Partner;
