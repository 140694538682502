import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { tendinaSlide } from '../utils/animations';
import unique from 'array-unique';

function SelettoreProvince({ setProv, data }) {
  const [isOpen, setIsOpen] = useState(false);
  const provHandler = () => {
    setIsOpen(!isOpen);
  };
  const selectHandler = (prov) => {
    setProv(prov);
    provHandler();
  };

  let prov = [];
  data.forEach((element) => {
    prov.push(element.provincia);
  });
  unique(prov);

  return (
    <div className='relative'>
      <div>
        <button
          onClick={provHandler}
          className='bg-red-600 duration-300 transform hover:-translate-y-1 hover:shadow-lg hover:bg-red-500 border text-white py-4 rounded-full px-6'
        >
          Seleziona una Provincia
        </button>

        {isOpen && (
          <motion.div
            initial='hidden'
            variants={tendinaSlide}
            animate={isOpen ? 'show' : 'close'}
            className='origin-bottom-left absolute mt-2 left-0 bg-gray-100 border border-gray-200 w-40 rounded-lg shadow-md h-screen/2 overflow-y-scroll'
          >
            <p
              onClick={() => selectHandler('tutte')}
              className='text-gray-900 block  hover:underline cursor-pointer p-3 hover:bg-gray-200'
            >
              Tutte
            </p>
            {prov.map((prov) => (
              <p
                onClick={() => selectHandler(prov)}
                key={prov}
                className='text-gray-900 block  hover:underline cursor-pointer p-3 hover:bg-gray-200'
              >
                {prov}
              </p>
            ))}
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default SelettoreProvince;
