import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import normativa_pdf from '../assets/quadro_normativo.pdf';
import safety from '../assets/safety.pdf';
import sentenza from '../assets/sentenza.pdf';
import NewsCard from '../components/incidenti/NewsCard';
import { normativa } from '../utils/data';
import banner from '../assets/images/quadro_normativo.png';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function QuadroNormativo() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='overflow-hidden'
    >
      <div
        className='w-full flex justify-center relative flex-shrink-0'
        style={{ backgroundColor: '#81adb8' }}
      >
        <p className='absolute inset-y-0 top-4 sm:top-6 font-semibold sm:font-bold text-white text-5xl text-center'>
          Hai usato Angelocks?
        </p>
        <img className='mt-10' src={banner} alt='banner' />
      </div>
      <div className='mx-auto container px-3'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Quadro normativo
        </div>
        <div className='container mx-auto'>
          <div className='space-y-4 sm:space-y-0 text-center sm:flex justify-evenly'>
            <a
              href={safety}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Normativa contro le cadute accidentali Singapore (ENG)
            </a>
            <a
              href={normativa_pdf}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Quadro normativo responsabilità immobiliare
            </a>
            <a
              href={sentenza}
              download
              className='text-blue-600 flex flex-col items-center hover:underline'
            >
              <AiOutlineFilePdf className='text-5xl' />
              Sentenza risarcimento danni, proprietà immobile
            </a>
          </div>
          <div className='grid sm:grid-flow-row md:grid-cols-3 gap-5 py-10'>
            {normativa.map((norm) => (
              <NewsCard
                img={norm.img}
                desc={norm.desc}
                link={norm.link}
                key={norm.id}
              />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default QuadroNormativo;
