import React from 'react';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';
// Images
import albori from '../assets/images/albori.png';
import attuale from '../assets/images/attuale.png';
import prima from '../assets/images/prima.png';

function ChiSiamo() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='pt-10 px-3 text-2xl text-white bg-blue-900'
    >
      <div className='container mx-auto'>
        <div className='font-bold text-5xl border-l-4 border-gray-900 pl-5 mb-10'>
          Chi Siamo
        </div>
        <div>
          <p>SERRAMETAL SRL®</p>
          <p>
            Ogni prodotto deve contenere tutta la tecnologia e l’esperienza
            dell’azienda che lo realizza, oltre che diventare la sua migliore
            referenza. Dal 1962 Bruno Vesca fondatore
          </p>
          <div className='md:w-1/2 my-10'>
            <div className='overflow-hidden rounded-xl mb-2'>
              <img src={albori} alt='' />
            </div>
            <p className='text-base'>
              1962 prima sede via Roma Mortegliano (UD)
            </p>
          </div>
          <div>
            <p>Iscritta Albo imprese Artigiane Provincia di Udine N.34837</p>
            <p>
              Costruzione di serramenti, arredamenti, carpenteria metallica,
              impianti elettrici
            </p>
            <div className='md:w-1/2 my-10'>
              <div className='overflow-hidden rounded-xl mb-2'>
                <img className='w-full' src={prima} alt='' />
              </div>
              <p className='text-base'>
                1971 sede in via Buiatti, 40 Mortegliano (UD)
              </p>
              <p className='text-base'>
                Stabilimento produttivo Mq.4000 + 13000 mq scoperto
              </p>
            </div>
          </div>
          <div className='space-y-3 my-10'>
            <p>
              <span className='font-bold'>1983</span> Riconoscimento officina
              fiduciaria Orsogril
            </p>
            <p>
              <span className='font-bold'>1986</span> Attestato di
              riconoscimento attività formativa professionale
            </p>
            <p>
              <span className='font-bold'>1986</span> Certificato attività di
              impianti elettrici. Installazione di impianti elettronici.
            </p>
            <p>
              <span className='font-bold'>1990</span> Diploma benemerenza Unione
              Artigiani del Friuli
            </p>
            <p>
              <span className='font-bold'>1991</span> Riconoscimento
              concessionaria Orsogril
            </p>
            <p>
              <span className='font-bold'>1992</span> Medaglia d'oro 39°
              premiazione CCIAA
            </p>
            <p>
              <span className='font-bold'>1996</span> Apertura unità locale via
              Centrale Lignano Sabbiadoro (UD)
            </p>
            <p>
              <span className='font-bold'>2000</span> Costruttore qualificato
              opere pubbliche SOA
            </p>
            <p>OG1 IIIBIS FINO A €1500.000,00</p>
            <p>OS18A II FINO A € 516.000,00</p>
            <p>OS18B I FINO A € 258.000,00</p>
            <p>OS30 I FINO A € 258.000,00</p>
            <p>OS6 IIIBIS FINO A €1500.000,00</p>
            <p>
              <span className='font-bold'>2003</span> UNI EN ISO 9001
            </p>
            <p>
              <span className='font-bold'>2005</span> UNI EN ISO 3834-2
            </p>
            <p>
              <span className='font-bold'>2006</span> Subentro nella gestione
              operattiva dei figli Giuseppe e Stefania
            </p>
            <p>
              <span className='font-bold'>2009</span> Benemerenza 56°
              premiazione CCIAA di Udine per progresso economico
            </p>
            <p>
              <span className='font-bold'>2010</span> Installazione fotovoltaico
              azienda zero emissioni
            </p>
            <p>
              <span className='font-bold'>2011</span> Attestato Centro di
              trasformazione acciaio n.1771/11
            </p>
            <p>
              <span className='font-bold'>2013</span> Attestato di
              riconoscimento capacità e competenze imprenditoriali
            </p>
            <p>
              <span className='font-bold'>2013</span> TUV Certificato controlli
              non distruttivi saldature (visivo - liquidi penetranti)
            </p>
            <p>
              <span className='font-bold'>2014</span> Brevetto internazionale
              Camaleante ®
            </p>
            <p>
              <span className='font-bold'>2015</span> Brevetto internazionale
              Angelocks ®
            </p>
            <p>
              <span className='font-bold'>2015</span> UNI EN ISO 1090-2 EX C3
            </p>
            <p>
              <span className='font-bold'>2017</span> Apertura unità locale via
              degli Artigiani Flumignano (UD)
            </p>
            <p>
              <span className='font-bold'>2017</span> Riconoscimento di "bottega
              scuola"per l'attività del ferro battuto e forgiato;
            </p>
            <p>
              <span className='font-bold'>2020</span> Apertura unità locale via
              degli Artigiani a Flumignano (UD)
            </p>
          </div>
          <div className='md:w-1/2 py-10'>
            <div className='overflow-hidden rounded-xl mb-2'>
              <img className='w-full' src={attuale} alt='' />
            </div>
            <p className='text-base'>Sede attuale</p>
          </div>
        </div>
        <p>Visita anche:</p>
        <div className='flex flex-col text-gray-100'>
          <a className='hover:underline' href='www.camaleante.com'>
            www.camaleante.com
          </a>
          <a className='hover:underline' href='www.serrametal.com'>
            www.serrametal.com
          </a>
        </div>
      </div>
    </motion.div>
  );
}

export default ChiSiamo;
