import React from 'react';
import ReactPlayer from 'react-player';
import { proveAngelocks } from '../utils/data';
import { Link } from 'react-router-dom';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';

function ProveAngelocks() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
      className='overflow-hidden cursor-default'
    >
      <div className='bg-black'>
        <ReactPlayer
          width={'100vw'}
          url='https://vimeo.com/387677359'
          controls={true}
          autoPlay={true}
        />
      </div>
      <div className='container mx-auto px-3 pt-8'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Prove Angelocks
        </div>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-3 py-5'>
          {/* PROVA 1 */}
          <div className='p-3 flex-auto flex flex-col justify-between bg-blue-100 rounded-xl h-52'>
            <p className='font-bold mb-3 text-xl text-center'>
              {proveAngelocks[0].titolo}
            </p>
            <div className='bg-red-600 text-white p-3 text-center uppercase transform duration-300 hover:-translate-y-1 hover:bg-red-500 rounded-xl'>
              <Link to='/prove-angelocks/prove-interne'>Scopri di più</Link>
            </div>
          </div>

          {/* PROVA 2 */}
          <div className='p-3 flex-auto flex flex-col justify-between bg-blue-100 rounded-xl h-52'>
            <a
              href={proveAngelocks[1].certificato}
              className='font-bold mb-3 text-xl text-center text-blue-500'
              target='_blank'
              rel='noreferrer'
            >
              {proveAngelocks[1].titolo}
            </a>
            <div className='uppercase p-3 bg-red-600 text-white text-center transform duration-300 hover:bg-red-500 hover:-translate-y-1 rounded-xl'>
              <a href='mailto:marketing@serrametal.com'>
                Per ulteriori dettagli, contattare marketing@serrametal.com
              </a>
            </div>
          </div>

          {/* PROVA 3 */}
          <div className='p-3 flex-auto flex flex-col justify-between bg-blue-100 rounded-xl h-52'>
            <a
              href={proveAngelocks[2].certificato}
              className='font-bold mb-3 text-xl text-center  text-blue-500'
              target='_blank'
              rel='noreferrer'
            >
              {proveAngelocks[2].titolo}
            </a>
            <div className='uppercase p-3 bg-red-600 text-white text-center transform duration-300 hover:bg-red-500 hover:-translate-y-1 rounded-xl'>
              <a href='mailto:marketing@serrametal.com'>
                Per ulteriori dettagli, contattare marketing@serrametal.com
              </a>
            </div>
          </div>
          {/* PROVA 4 */}
          <div className='p-3 flex-auto flex flex-col justify-between bg-blue-100 rounded-xl h-52'>
            <p className='font-bold mb-3 text-xl text-center'>
              {proveAngelocks[3].titolo}
            </p>
            <div className='uppercase p-3 bg-red-600 text-white text-center transform duration-300 hover:bg-red-500 hover:-translate-y-1 rounded-xl'>
              <a
                href={proveAngelocks[3].certificato}
                target='_blank'
                rel='noreferrer'
              >
                Scarica il PDF
              </a>
            </div>
          </div>
          {/* PROVA 5 */}
          <div className='p-3 flex-auto flex flex-col justify-between bg-blue-100 rounded-xl h-52'>
            <p className='font-bold mb-3 text-xl text-center'>
              {proveAngelocks[4].titolo}
            </p>
            <div className='p-3 text-center uppercase'>
              <p>{proveAngelocks[4].desc}</p>
            </div>
          </div>
          {/* PROVA 6 */}
          <div className='p-3 flex-auto flex flex-col justify-between bg-blue-100 rounded-xl h-52'>
            <div>
              <p className='font-bold mb-3 text-xl text-center'>
                Qualità testata e certificata.
              </p>
              <p className='font-bold mb-3 text-xl text-center'>
                Da noi per Voi.
              </p>
            </div>
            <div className='bg-red-600 text-white p-3 text-center uppercase transform duration-300 hover:-translate-y-1 hover:bg-red-500 rounded-xl'>
              <Link to='/da-noi'>Scopri di più</Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ProveAngelocks;
