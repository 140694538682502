import React from 'react';
import product from '../../assets/images/product.jpeg';

function Intro() {
  return (
    <div className='md:flex items-center container mx-auto px-3 py-3 md:py-16'>
      <div className='overflow-hidden rounded-xl w-full md:w-1/2 lg:w-1/3 flex-shrink-0 mb-5 md:mb-0'>
        <img src={product} alt='angelocks-product' />
      </div>
      <div className='w-full md:pl-10 text-lg'>
        <p>
          <span className='font-bold'>Angelocks</span> è L’angelo custode delle
          Vostre finestre.
        </p>
        <p>
          E' un
          <span className='text-red-600 font-bold'>
            &nbsp;brevetto italiano registrato in tutto il mondo.&nbsp;
          </span>
          In particolare: Italia, Europa, Russia, Cina e Stati Uniti.
        </p>
        <p>
          Evita la caduta accidentale delle ante dei serramenti e scuretti,
          causa di grave pericolo per l’incolumità, la vita delle persone e il
          danneggiamento delle cose.
        </p>
        <p>E' facile da installare.</p>
        <p>Non interferisce con la ferramenta già montata sul serramento.</p>
        <p>E’ un accessorio a basso costo.</p>
        <p>
          <span className='font-bold'>Angelocks</span>, finalmente una sicurezza
          per le Vostre finestre!
        </p>
      </div>
    </div>
  );
}

export default Intro;
