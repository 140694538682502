import React from 'react';
import fiera from '../assets/images/fiera.jpeg';
import ReactPlayer from 'react-player';
//Animation
import { motion } from 'framer-motion';
import { pageTransition } from '../utils/animations';
//images
import { imgForum } from '../utils/data';

function ForumBologna() {
  return (
    <motion.div
      variants={pageTransition}
      initial='hidden'
      animate='show'
      exit='exit'
    >
      <div
        className='h-screen/2 overflow-hidden bg-no-repeat bg-center bg-cover'
        style={{ backgroundImage: `url(${fiera})` }}
      ></div>
      <div className='container mx-auto px-3'>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Forum sicurezza serramenti di Bologna
        </div>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-3'>
          {imgForum.map((img) => (
            <div>
              <img src={img} />
            </div>
          ))}
        </div>
        <div className='font-bold text-5xl border-l-4 border-blue-900 pl-5 mt-5 mb-10'>
          Guarda il video!
        </div>
        <div className='mb-10'>
          <ReactPlayer url='https://vimeo.com/393900852' controls={true} />
        </div>
      </div>
    </motion.div>
  );
}

export default ForumBologna;
