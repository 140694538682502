import React from 'react';
import { Switch, Route } from 'react-router-dom';
//Components
import Navigation from './components/navigation/Navigation';
import TheFooter from './components/TheFooter';
import ScrollTop from './components/ScrollTop';
//Pages
import Home from './pages/Home';
import Incidenti from './pages/Incidenti';
import IncidentiItalia from './pages/IncidentiItalia';
import IncidentiEstero from './pages/IncidentiEstero';
import ProveAngelocks from './pages/ProveAngelocks';
import Cataloghi from './pages/Cataloghi';
import QuadroNormativo from './pages/QuadroNormativo';
import Contatti from './pages/Contatti';
import ProveInterne from './pages/ProveInterne';
import Privacy from './pages/Privacy';
//Animation
import { AnimatePresence } from 'framer-motion';
import ForumBologna from './pages/ForumBologna';
import Partner from './pages/Partner';
import Personalizza from './pages/Personalizza';
import ChiSiamo from './pages/ChiSiamo';
import DaNoi from './pages/DaNoi';
import News from './pages/News';

function App() {
  return (
    <>
      <Navigation />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>
          <Route path='/incidenti' exact>
            <Incidenti />
          </Route>
          <Route path='/incidenti/italia'>
            <IncidentiItalia />
          </Route>
          <Route path='/incidenti/estero'>
            <IncidentiEstero />
          </Route>
          <Route path='/prove-angelocks' exact>
            <ProveAngelocks />
          </Route>
          <Route path='/prove-angelocks/prove-interne'>
            <ProveInterne />
          </Route>
          <Route path='/cataloghi'>
            <Cataloghi />
          </Route>
          <Route path='/forum-bologna'>
            <ForumBologna />
          </Route>
          <Route path='/news-1'>
            <News />
          </Route>
          <Route path='/partner'>
            <Partner />
          </Route>
          <Route path='/chi-siamo'>
            <ChiSiamo />
          </Route>
          <Route path='/da-noi'>
            <DaNoi />
          </Route>
          <Route path='/angelocks-special'>
            <Personalizza />
          </Route>
          <Route path='/quadro-normativo'>
            <QuadroNormativo />
          </Route>
          <Route path='/contatti'>
            <Contatti />
          </Route>
          <Route path='/privacy'>
            <Privacy />
          </Route>
        </Switch>
      </AnimatePresence>
      <TheFooter />
      <ScrollTop />
    </>
  );
}

export default App;
